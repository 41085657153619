import { userService } from 'common/services/user.service';
import { Overview as OverviewV2 } from 'modules/overview-v2';
import { PublishersSettings } from 'modules/publishers-settings';
import { PublisherSettings } from 'modules/settings/components/publishers-settings/publisher-settings';
import { ParentPublisherSettings } from 'modules/parent-publisher-settings';
import { UserSettings } from 'modules/user-settings';
import { RevenueByPage } from 'modules/revenue-by-page';
import { Management, ReplaceProducts } from 'modules/content-overview';
import { Tags } from 'modules/tags';
import { Urls } from 'modules/urls';
import { AsinFinder } from 'modules/asin-finder/asin-finder';
import { Attribution } from 'modules/attribution';
import { FunctionComponent } from 'react';
import { Stats } from 'modules/stats';
import { Jobs } from 'modules/jobs';
import { Conversions } from 'modules/conversions';
import { SearchSuggestions } from 'modules/search-suggestion';
import { PagePerformance } from 'modules/content-overview/components/pages/page-performance';
import { RecommendedAsinsV2 } from 'modules/affiliate-gainz/components/pages/recommended-asins-v2';
import { MavPlus } from 'modules/mav-plus/mav-plus-page';
import { AsinRecommendation } from 'modules/asin-recommendation';
import { PubXReports } from 'modules/pubx-reports';
import { PublisherAffiliateTags } from 'modules/publishers-affiliate-tags';
import { AsinsConversionsBoost } from 'modules/asins-conversions-boost';
import { PERMISSIONS } from './permissions';
import { AmazonAffiliateReports } from '../../modules/amazon-affiliate-reports';

interface Route {
  path: string;
  component: FunctionComponent;
  isAvailable: boolean;
}

export const getRoutes = (): Array<Route> => [
  {
    path: '/',
    component: OverviewV2,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
  },
  {
    path: '/overview-v2',
    component: OverviewV2,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
  },
  {
    path: '/revenue-by-page',
    component: RevenueByPage,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
  },
  {
    path: '/content-overview/replace',
    component: ReplaceProducts,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_CONTENT_OVERVIEW]),
  },
  {
    path: '/content-overview/performance',
    component: PagePerformance,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_CONTENT_OVERVIEW]),
  },
  {
    path: '/content-overview/management',
    component: Management,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_CONTENT_OVERVIEW]),
  },
  {
    path: '/content-overview/management/:nav',
    component: Management,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_CONTENT_OVERVIEW]),
  },
  {
    path: '/mavp',
    component: MavPlus,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
  },
  {
    path: '/settings/publishers',
    component: PublishersSettings,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS]),
  },
  {
    path: '/publisher/:nav',
    component: PublisherSettings,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHER]),
  },
  {
    path: '/settings/tags',
    component: Tags,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_TAGS]),
  },
  {
    path: '/settings/urls',
    component: Urls,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_TAGS]),
  },
  {
    path: '/settings/user',
    component: UserSettings,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_USER_SETTINGS]),
  },
  {
    path: '/attribution',
    component: Attribution,
    isAvailable: true,
  },
  {
    path: '/stats',
    component: Stats,
    isAvailable: false, // page not used
  },
  {
    path: '/jobs/:nav',
    component: Jobs,
    isAvailable: true,
  },
  {
    path: '/conversions',
    component: Conversions,
    isAvailable: true,
  },
  {
    path: '/tools/asin-finder',
    component: AsinFinder,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS]),
  },
  {
    path: '/tools/asin-suggestion',
    component: SearchSuggestions,
    isAvailable: true,
  },
  {
    path: '/affiliate-gainz/recommend-v2',
    component: RecommendedAsinsV2,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
  },
  {
    path: '/affiliate-gainz/recommend/',
    component: AsinRecommendation,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
  },
  {
    path: '/tools/pubx-reports',
    component: PubXReports,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS]),
  },
  {
    path: '/tools/amazon-affiliate-reports',
    component: AmazonAffiliateReports,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
  },
  {
    path: '/settings/publishers-tags',
    component: PublisherAffiliateTags,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
  },
  {
    path: '/settings/parent-publishers',
    component: ParentPublisherSettings,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS]),
  },
  {
    path: '/tools/asins-conversions-boost',
    component: AsinsConversionsBoost,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
  },
];
