import { Button, Typography, Box, Switch } from '@mui/material';
import { Styles } from 'common/types';
import AddIcon from '@mui/icons-material/Add';
import { theme } from 'common/constants/theme';
import { selectGlobalFilters, selectRootDemo } from 'common/store/selectors';
import { putDemoMode } from 'common/store/actions';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { publishersSettingsActions as publisherActions } from 'modules/settings/store/actions/actions';
import HeaderImg from 'common/svg/header-img.png';
import { recommendedAsinsActions } from 'modules/affiliate-gainz/store/recommended-asins/actions';
import { parentPublisherSettingsActions } from 'modules/parent-publisher-settings/store/actions';
import { Banner } from 'modules/parent-publisher-settings/components/banner';
import { PublisherAction, PublisherModal, PublishersTable } from './components';
import { publishersParentsActions, publishersSettingsActions } from './store/actions';
import {
  selectPublishers,
  selectPublishersSettingsFilters,
  selectPublishersSettingsTableFilters,
} from './store/selectors';
import { FiltersModal } from './components/filters-modal';

const styles: Styles = {
  addButton: { marginTop: 1, color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark },
  bttnWrapper: { display: 'flex', justifyContent: 'space-between' },
  demoWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '12px' },
  titleContainer: { display: 'flex', justifyContent: 'space-between' },
  filtersContainer: { display: 'flex', alignItems: 'center', gap: 1.5 },
  headTitle: {
    color: '#ffffff',
    fontWeight: 600,
  },
  head: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `url(${HeaderImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#4288F0',
    padding: '28px',
    boxSizing: 'border-box',
    color: '#ffffff',
    fontWeight: 600,
  },
};

export function PublishersSettings(): ReactElement {
  const dispatch = useDispatch();

  const publishers = useSelector(selectPublishers);
  const isDemoMode = useSelector(selectRootDemo);
  const filters = useSelector(selectPublishersSettingsFilters);
  const tableFilters = useSelector(selectPublishersSettingsTableFilters);
  const globalFilters = useSelector(selectGlobalFilters);

  const [init, setInit] = useState<boolean>(false);
  const [targetPublisher, setTargetPublisher] = useState<PublishersSettingsModule.Publisher | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [publisherAction, setPublisherAction] = useState<PublisherAction | null>(null);

  const filtersParams = useMemo(
    () => ({
      ...(globalFilters.search === '' ? {} : { search: globalFilters.search }),
      group: tableFilters.group,
      gainz: tableFilters.gainz,
    }),
    [tableFilters, globalFilters.search]
  );

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleFiltersModal = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleFiltersModalClose = () => {
    setFiltersOpen(false);
  };

  const handleEditClick = (publisherId: number) => {
    const foundPublisher = publishers.find(publisher => publisher.id === publisherId);
    if (!foundPublisher) return;
    setTargetPublisher(foundPublisher);
    setPublisherAction(PublisherAction.edit);
    setEditModalOpen(true);
  };

  const handleCreateClick = () => {
    setPublisherAction(PublisherAction.create);
    setEditModalOpen(true);
  };

  const handleChangeDemoMode = () => {
    if (isDemoMode) {
      localStorage.setItem('demo', '0');
      dispatch(putDemoMode(false));
    } else {
      localStorage.setItem('demo', '1');
      dispatch(putDemoMode(true));
    }
  };

  const handleApply = () => {
    setFiltersOpen(false);
    dispatch(publishersSettingsActions.getPublishers(filtersParams));
  };

  const handleReset = () => {
    dispatch(publishersSettingsActions.resetFilters());
  };

  const debouncedPublishers = useDebouncedCallback(() => {
    dispatch(publishersSettingsActions.getPublishers(filtersParams));
  }, 300);

  useEffect(() => {
    if (init) debouncedPublishers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersParams.search]);

  useEffect(() => {
    setInit(true);
    dispatch(publishersSettingsActions.getPublishers(filtersParams));
    dispatch(publisherActions.getPublisherGroups());
    dispatch(publishersParentsActions.get({}));
    dispatch(recommendedAsinsActions.getCategories(filters?.publisher_id.toString()));
    dispatch(parentPublisherSettingsActions.getPublisherAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Banner title="Publishers" options={{ search: true, filters: true }} handleFiltersOpen={handleFiltersModal} />
      <Box sx={styles.bttnWrapper}>
        <Button sx={styles.addButton} variant="outlined" onClick={handleCreateClick}>
          <AddIcon fontSize="small" />
          Add publisher
        </Button>
        <Box sx={styles.demoWrapper}>
          <Typography>Demo mode:</Typography>
          <Switch checked={isDemoMode} onClick={handleChangeDemoMode} />
        </Box>
      </Box>
      <PublishersTable handleEditClick={handleEditClick} />
      <PublisherModal
        open={editModalOpen}
        action={publisherAction}
        publisher={publisherAction === PublisherAction.edit ? targetPublisher : undefined}
        onClose={handleEditModalClose}
      />
      <FiltersModal
        isOpen={filtersOpen}
        onClose={handleFiltersModalClose}
        handleApply={handleApply}
        handleReset={handleReset}
      />
    </>
  );
}
