import { saveAs } from 'file-saver';
import { ApiService } from 'common/services';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';

class SettingsApiService extends ApiService {
  getPublisherInfo = (id: number) => {
    return this.get(`/api/settings/publishers/${id}`);
  };

  patchPublisherInfo = (payload: PublishersSettingsModule.MainPublisherSettingsForm) => {
    return this.patchFormData(`/api/settings/publishers/${payload.id}`, {
      ...(payload.logo ? { file: payload.logo } : {}),
      ...(payload.logo === null ? { logo: null } : {}),
      ...(payload.isNameChanged ? { name: payload.name } : {}),
      ...(payload.isDomainChanged ? { domain: payload.domain } : {}),
      ...(payload.timezone ? { timezone: payload.timezone } : {}),
      ...(payload.isEmbed ? { embed: payload?.embed } : {}),
      ...(payload.isAttributed ? { ordered_conversions_name: payload?.ordered_conversions_name } : {}),
      ...(payload.isAttributed ? { shipped_conversions_name: payload?.shipped_conversions_name } : {}),
      ...(payload.is_amazon_attribution !== undefined ? { is_amazon_attribution: payload?.is_amazon_attribution } : {}),
      ...(payload.is_tracking_active !== undefined ? { is_tracking_active: payload?.is_tracking_active } : {}),
      ...(payload.tagging_networks !== undefined ? { tagging_networks: payload?.tagging_networks } : {}),
      ...(payload.attribution_path_regex !== undefined
        ? { attribution_path_regex: payload?.attribution_path_regex }
        : {}),
      ...(payload.affiliate_path_regex !== undefined ? { affiliate_path_regex: payload?.affiliate_path_regex } : {}),
    });
  };

  patchPublisherCategories = (payload: PublishersSettingsModule.MainPublisherSettingsForm) => {
    return this.patch(`/api/settings/publishers/${payload.id}`, {
      ...(payload.publisher_categories !== undefined ? { categories: payload?.publisher_categories } : {}),
    });
  };

  getUrls = (filters: IStatisticsQueryString) => {
    return this.get('/api/urls', {
      params: { limit: filters.limit, offset: filters.offset, publisher_ids: [filters.publisher_id] },
    });
  };

  postUrlDailyScraping = (payload: { url_scheduled_scraping: boolean; id: number }) => {
    return this.post(`/api/settings/publishers/${payload.id}/schedule-scraping`, payload);
  };

  getPublisherAffiliate = (id: number) => {
    return this.get(`/api/settings/publishers/${id}/affiliate`);
  };

  patchPublisherAffiliate = (payload: PublishersSettingsModule.AffiliateSettingsForm) => {
    return this.patch(`/api/settings/publishers/${payload.id}/affiliate`, {
      ...(payload.amazon_login ? { amazon_login: payload.amazon_login } : {}),
      ...(payload.amazon_password ? { amazon_password: payload.amazon_password } : {}),
      ...(payload.amazon_associates_url ? { amazon_associates_url: payload.amazon_associates_url } : {}),
      ...(payload.skm_publisher_id ? { skm_publisher_id: payload.skm_publisher_id } : {}),
      ...(payload.skm_client_id ? { skm_client_id: payload.skm_client_id } : {}),
      ...(payload.skm_client_secret ? { skm_client_secret: payload.skm_client_secret } : {}),
      ...(payload.imp_account_sid ? { imp_account_sid: payload.imp_account_sid } : {}),
      ...(payload.imp_account_token ? { imp_account_token: payload.imp_account_token } : {}),
    });
  };

  getPublisherTeam = (id: number) => {
    return this.get(`/api/settings/publishers/${id}/team`);
  };

  getWordpressPlugin = (id: number) => {
    return this.get(`/api/settings/publishers/${id}/wordpress-plugin`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      const filename = 'carrick-plugin.zip';
      saveAs(blob, filename);
    });
  };

  addPublisherTag = (payload: PublishersSettingsModule.Tag) => {
    return this.post('/api/tags', payload);
  };

  deletePublisherTag = (payload: string) => {
    return this.delete(`/api/tags/${payload}`);
  };

  getIntegrationLink = (payload: { publisherId: number; provider: string }) => {
    return this.get(`/api/integrations/publisher/${payload.publisherId}/${payload.provider}/link`);
  };

  getIntegrationsList = (payload: number) => {
    return this.get(`/api/integrations/publisher/${payload}`);
  };

  deleteIntegrationsList = (payload: { publisherId: number; provider: string }) => {
    return this.delete(`/api/integrations/publisher/${payload.publisherId}/${payload.provider}`);
  };

  getRateCards = (payload: RateCardsModule.Params) => {
    return this.get('/api/settings/rate-cards', { params: payload });
  };

  patchRateCards = (payload: RateCardsModule.Patch) => {
    return this.patch('/api/settings/rate-cards', payload);
  };

  postAffiliateReport = (payload: AffiliateReportModule.UploadPost) => {
    const { publisher_id, file } = payload;

    return this.postFormData(`/api/settings/publishers/${publisher_id}/affiliate/reports/upload`, { file });
  };

  getAffiliateReportDownload = (payload: AffiliateReportModule.DownloadPayload) => {
    return this.post(
      `/api/settings/publishers/${payload.publisher_id}/affiliate/reports/download`,
      {},
      { params: { name: payload.file_name } }
    );
  };

  getAffiliateReportHistory = (payload: { publisher_id: number }) => {
    return this.get(`/api/settings/publishers/${payload.publisher_id}/affiliate/reports`, { params: payload });
  };

  sendInvitation = (payload: { email: string; clientId: string }) => {
    return this.post('/api/settings/invitation', {
      email: payload.email,
      client_type: 'publisher',
      client_id: payload.clientId,
    });
  };

  getPendingInvitations = (payload: { clientId: string }) => {
    return this.get('/api/settings/invitation/pending', {
      params: {
        client_type: 'publisher',
        client_id: payload.clientId,
      },
    });
  };

  deleteTeamMember = (payload: { publisherId: number; id: string }) => {
    return this.delete(`/api/settings/publishers/${payload.publisherId}/team/${payload.id}`);
  };

  generateTag = (publisherId: number, asin: string, url: string) => {
    return this.post('/api/amazon-attribution/tags/generate', { publisher_id: publisherId, asin, url });
  };

  getPublisherGroups = () => {
    return this.get('/api/presets/filters/publisher-groups/');
  };

  getTeam = (payload?: number) => {
    return this.get('/api/team', {
      params: {
        client_type: 'publisher',
        ...(payload ? { client_id: payload } : {}),
      },
    });
  };

  getClients = () => {
    return this.get('/api/team/clients', {
      params: {
        client_type: 'publisher',
      },
    });
  };

  patchTeamUser = (payload: PublishersSettingsModule.PatchUserTeam) => {
    return this.post('/api/team/invite', payload);
  };

  cancelTeamUser = (payload: string) => {
    return this.post('/api/team/invite/cancel', {
      client_type: 'publisher',
      email: payload,
    });
  };

  resendTeamUser = (payload: string) => {
    return this.post('/api/team/invite/resend', {
      client_type: 'publisher',
      email: payload,
    });
  };

  patchDefaulTag = (payload: { publisher_id: number; default_tag: string }) => {
    return this.patch(`/api/settings/publishers/${payload.publisher_id}/tag`, {
      default_tag: payload.default_tag,
    });
  };
}

export const settingsApi = new SettingsApiService(process.env.REACT_APP_API_URL);
