import { State } from 'common/store/reducers';

export const selectPublishersTags = (state: State) => state.publishersTagsReducer.publishersTags.tags;

export const selectPublishersTagsLoading = (state: State) => state.publishersTagsReducer.publishersTags.loading;

export const selectPublishersTagsSort = (state: State) => state.publishersTagsReducer.publishersTags.sort;

export const selectCurrentPublishersTagsPage = (state: State) => state.publishersTagsReducer.publishersTags.page;

export const selectLastScheduledImport = (state: State) =>
  state.publishersTagsReducer.publishersTags.scheduledTagsImport;

export const selectLastTagsImportStatus = (state: State) => state.publishersTagsReducer.publishersTags.tagsImportStatus;

export const selectError = (state: State) => state.publishersTagsReducer.publishersTags.error;
