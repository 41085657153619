import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { DeleteModal } from 'common/ui/delete-modal';
import { TableContainerWithLoading } from 'common/ui/table-container-with-loading';
import { publishersSettingsActions } from 'modules/publishers-settings/store/actions';
import { selectPublishers, selectPublishersLoading } from 'modules/publishers-settings/store/selectors';
import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PublisherRow } from './publisher-row';

const styles: Styles = {
  table: { marginTop: 2 },
};

interface Props {
  handleEditClick: (publisherId: number) => void;
}

export function PublishersTable({ handleEditClick }: Props): ReactElement {
  const dispatch = useDispatch();

  const loading = useSelector(selectPublishersLoading);
  const publishers = useSelector(selectPublishers);

  const [targetPublisherToDelete, setTargetPublisherToDelete] = useState<number>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handlePublisherDeleteModalOpen = (publisherId: number) => {
    const foundPublisher = publishers.find(publisher => publisher.id === publisherId);
    if (!foundPublisher) return;
    setTargetPublisherToDelete(foundPublisher.id);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setTargetPublisherToDelete(null);
    setDeleteModalOpen(false);
  };

  const handlePublisherDelete = () => {
    if (!targetPublisherToDelete) return;
    dispatch(publishersSettingsActions.deletePublisher(targetPublisherToDelete));
    handleDeleteModalClose();
  };

  return (
    <>
      <TableContainerWithLoading sx={styles.table} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Parent Publisher</TableCell>
              <TableCell>Slug</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Publisher groups</TableCell>
              <TableCell>Blocked Brands</TableCell>
              <TableCell>Gainz</TableCell>
              <TableCell>Commission rate</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {publishers?.map(publisher => (
              <PublisherRow
                key={publisher.id}
                onDelete={handlePublisherDeleteModalOpen}
                onEdit={handleEditClick}
                publisher={publisher}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainerWithLoading>
      <DeleteModal open={deleteModalOpen} onClose={handleDeleteModalClose} onConfirm={handlePublisherDelete} />
    </>
  );
}
