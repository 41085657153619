import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { publishersSettingsActionTypes as T } from './action-types';

const getMainPublisherInfo = (payload: string) => ({
  type: T.GET_MAIN_PUBLISHER_INFO,
  payload,
});

const getMainPublisherInfoSuccess = (data: PublishersSettingsModule.MainPublisherSettingsForm) => ({
  type: T.GET_MAIN_PUBLISHER_INFO_SUCCESS,
  payload: { data },
});

const getMainPublisherInfoError = (error: Error) => ({
  type: T.GET_MAIN_PUBLISHER_INFO_ERROR,
  payload: error,
});

const patchMainPublisherInfo = (
  payload: PublishersSettingsModule.MainPublisherSettingsForm,
  onSuccess: () => void
) => ({
  type: T.PATCH_MAIN_PUBLISHER_INFO,
  payload,
  meta: { onSuccess },
});

const patchMainPublisherInfoSuccess = () => ({
  type: T.PATCH_MAIN_PUBLISHER_INFO_SUCCESS,
});

const patchMainPublisherInfoError = (error: Error) => ({
  type: T.PATCH_MAIN_PUBLISHER_INFO_ERROR,
  payload: error,
});

const patchMainPublisherCategories = (
  payload: PublishersSettingsModule.MainPublisherSettingsForm,
  onSuccess: () => void
) => ({
  type: T.PATCH_MAIN_PUBLISHER_CATEGORIES,
  payload,
  meta: { onSuccess },
});

const patchMainPublisherCategoriesSuccess = () => ({
  type: T.PATCH_MAIN_PUBLISHER_CATEGORIES_SUCCESS,
});

const patchMainPublisherCategoriesError = (error: Error) => ({
  type: T.PATCH_MAIN_PUBLISHER_CATEGORIES_ERROR,
  payload: error,
});

const getPublisherTags = (filters: TagsModule.GetTagsParams) => ({
  type: T.GET_PUBLISHER_TAGS,
  payload: filters,
});

const getPublisherTagsSuccess = (tags: TagsModule.TagsData) => ({
  type: T.GET_PUBLISHER_TAGS_SUCCESS,
  payload: tags,
});

const getPublisherTagsError = (error: Error) => ({
  type: T.GET_PUBLISHER_TAGS_ERROR,
  payload: error,
});

const changePublisherTagsPage = (page: number) => ({
  type: T.CHANGE_PUBLISHER_TAGS_PAGE,
  payload: page,
});

const addPublisherTag = (payload: PublishersSettingsModule.Tag, onSuccess: () => void) => ({
  type: T.ADD_PUBLISHER_TAG,
  payload,
  meta: { onSuccess },
});

const patchDefaultTag = (
  payload: PublishersSettingsModule.DefaultTagForm,
  onSuccess: () => void,
  onError: () => void
) => ({
  type: T.PATCH_DEFAULT_TAG,
  payload,
  meta: { onSuccess, onError },
});

const setTrafficType = (type: string) => ({
  type: T.SET_TRAFFIC_TYPE,
  payload: type,
});

const setUsed = (used: string) => ({
  type: T.SET_USED,
  payload: used,
});

const resetFilters = () => ({
  type: T.RESET_FILTERS,
});

const deletePublisherTag = (payload: string, onSuccess: () => void) => ({
  type: T.DELETE_PUBLISHER_TAG,
  payload,
  meta: { onSuccess },
});

const deletePublisherTagSuccess = () => ({
  type: T.DELETE_PUBLISHER_TAG_SUCCESS,
});

const deletePublisherTagError = (error: Error) => ({
  type: T.DELETE_PUBLISHER_TAG_ERROR,
  payload: { error },
});

const getPublisherUrls = (filters: IStatisticsQueryString) => ({
  type: T.GET_PUBLISHER_URLS,
  payload: filters,
});

const getPublisherUrlsSuccess = (urls: Array<UrlsModule.Url>, count: number) => ({
  type: T.GET_PUBLISHER_URLS_SUCCESS,
  payload: { urls, count },
});

const getPublisherUrlsError = (error: Error) => ({
  type: T.GET_PUBLISHER_URLS_ERROR,
  payload: error,
});

const changePublisherUrlsPage = (page: number) => ({
  type: T.CHANGE_PUBLISHER_URLS_PAGE,
  payload: page,
});

const changePublisherCrawler = (url_scheduled_scraping: boolean, id: number) => ({
  type: T.MAIN_CHANGE_PUBLISHER_CRAWLER,
  payload: { url_scheduled_scraping, id },
});

const getPublisherAffiliate = (payload: string) => ({
  type: T.GET_PUBLISHER_AFFILIATE,
  payload,
});

const getPublisherAffiliateSuccess = (data: Array<PublishersSettingsModule.AffiliateSettingsForm>) => ({
  type: T.GET_PUBLISHER_AFFILIATE_SUCCESS,
  payload: { data },
});

const getPublisherAffiliateError = (error: Error) => ({
  type: T.GET_PUBLISHER_AFFILIATE_ERROR,
  payload: error,
});

const patchPublisherAffiliate = (payload: PublishersSettingsModule.AffiliateSettingsForm, onSuccess: () => void) => ({
  type: T.PATCH_PUBLISHER_AFFILIATE,
  payload,
  meta: { onSuccess },
});

const patchPublisherAffiliateSuccess = () => ({
  type: T.PATCH_PUBLISHER_AFFILIATE_SUCCESS,
});

const patchPublisherAffiliateError = (error: Error) => ({
  type: T.PATCH_PUBLISHER_AFFILIATE_ERROR,
  payload: error,
});

const getPublisherTeam = (payload: string) => ({
  type: T.GET_PUBLISHER_TEAM,
  payload,
});

const getPublisherTeamSuccess = (data: Array<PublishersSettingsModule.UserFormData>) => ({
  type: T.GET_PUBLISHER_TEAM_SUCCESS,
  payload: { data },
});

const getPublisherTeamError = (error: Error) => ({
  type: T.GET_PUBLISHER_TEAM_ERROR,
  payload: error,
});

const getWordpressPlugin = (payload: string) => ({
  type: T.GET_WORDPRESS_PLUGIN,
  payload,
});

const getWordpressPluginSuccess = () => ({
  type: T.GET_WORDPRESS_PLUGIN_SUCCESS,
});

const getWordpressPluginError = (error: Error) => ({
  type: T.GET_WORDPRESS_PLUGIN_ERROR,
  payload: error,
});

const getIntegrationLink = (publisherId: number, provider: string) => ({
  type: T.GET_INTEGRATION_LINK,
  payload: { publisherId, provider },
});

const getIntegrationLinkError = (error: Error) => ({
  type: T.GET_INTEGRATION_LINK_ERROR,
  payload: error,
});

const getIntegrationsList = (payload: number) => ({
  type: T.GET_INTEGRATIONS_LIST,
  payload,
});

const getIntegrationsListSuccess = (payload: Array<PublishersSettingsModule.IntegrationFormData>) => ({
  type: T.GET_INTEGRATIONS_LIST_SUCCESS,
  payload,
});

const getIntegrationsListError = (error: Error) => ({
  type: T.GET_INTEGRATIONS_LIST_ERROR,
  payload: error,
});

const deleteIntegrationFromList = (publisherId: number, provider: string, onSuccess: () => void) => ({
  type: T.DELETE_INTEGRATION_FROM_LIST,
  payload: { publisherId, provider },
  meta: { onSuccess },
});

const deleteIntegrationFromListSuccess = () => ({
  type: T.DELETE_INTEGRATION_FROM_LIST_SUCCESS,
});

const deleteIntegrationFromListError = (error: Error) => ({
  type: T.DELETE_INTEGRATION_FROM_LIST_ERROR,
  payload: error,
});

const getRateCards = (params: RateCardsModule.Params) => ({
  type: T.GET_RATE_CARDS,
  payload: params,
});

const getRateCardsSuccess = (data: RateCardsModule.RateCard[]) => ({
  type: T.GET_RATE_CARDS_SUCCESS,
  payload: data,
});

const getRateCardsError = (error: Error) => ({
  type: T.GET_RATE_CARDS_ERROR,
  payload: error,
});

const setRateCardsSelected = (selected: { [x: number]: RateCardsModule.RateCard }) => ({
  type: T.SET_RATE_CARDS_SELECTED,
  payload: selected,
});

const patchRateCards = (payload: RateCardsModule.Patch, onSuccess: () => void) => ({
  type: T.PATCH_RATE_CARDS,
  payload,
  meta: { onSuccess },
});

const patchRateCardsSuccess = () => ({
  type: T.PATCH_RATE_CARDS_SUCCESS,
});

const patchRateCardsError = (error: Error) => ({
  type: T.PATCH_RATE_CARDS,
  payload: error,
});

const postAffiliateReport = (payload: AffiliateReportModule.UploadPost, onSuccess: () => void) => ({
  type: T.POST_AFFILIATE_REPORT,
  payload,
  meta: { onSuccess },
});

const postAffiliateReportSuccess = (payload: string) => ({
  type: T.POST_AFFILIATE_REPORT_SUCCESS,
  payload,
});

const postAffiliateReportError = (error: Error) => ({
  type: T.POST_AFFILIATE_REPORT_ERROR,
  payload: error,
});

const getAffiliateReportDownload = (payload: AffiliateReportModule.DownloadPayload) => ({
  type: T.GET_AFFILIATE_REPORT_DOWNLOAD,
  payload,
});

const getAffiliateReportDownloadSuccess = (payload: AffiliateReportModule.DownloadSuccessPayload) => ({
  type: T.GET_AFFILIATE_REPORT_DOWNLOAD_SUCCESS,
  payload,
});

const getAffiliateReportDownloadError = (error: Error) => ({
  type: T.GET_AFFILIATE_REPORT_DOWNLOAD_ERROR,
  payload: error,
});

const getAffiliateReportHistory = (payload: { publisher_id: number }) => ({
  type: T.GET_AFFILIATE_REPORT_HISTORY,
  payload,
});

const getAffiliateReportHistorySuccess = (data: AffiliateReportModule.HistoryRow[]) => ({
  type: T.GET_AFFILIATE_REPORT_HISTORY_SUCCESS,
  payload: data,
});

const getAffiliateReportHistoryError = (error: Error) => ({
  type: T.GET_AFFILIATE_REPORT_HISTORY_ERROR,
  payload: error,
});

const sendInvitation = (email: string, clientId: string, onSuccess: () => void, onError: () => void) => ({
  type: T.SEND_INVITATION,
  payload: { email, clientId },
  meta: { onSuccess, onError },
});

const sendInvitationSuccess = () => ({
  type: T.SEND_INVITATION_SUCCESS,
});

const sendInvitationError = (error: Error) => ({
  type: T.SEND_INVITATION_ERROR,
  payload: error,
});

const getPendingInvitations = (clientId: string) => ({
  type: T.GET_PENDING_INVITATIONS,
  payload: { clientId },
});

const getPendingInvitationsSuccess = (data: Array<{ email: string }>) => ({
  type: T.GET_PENDING_INVITATIONS_SUCCESS,
  payload: { data },
});

const getPendingInvitationsError = (error: Error) => ({
  type: T.GET_PENDING_INVITATIONS_ERROR,
  payload: error,
});

const deleteTeamMember = (publisherId: number, id: string, onSuccess: () => void) => ({
  type: T.DELETE_TEAM_MEMBER,
  payload: { publisherId, id },
  meta: { onSuccess },
});

const deleteTeamMemberSuccess = () => ({
  type: T.DELETE_TEAM_MEMBER_SUCCESS,
});

const deleteTeamMemberError = (error: Error) => ({
  type: T.DELETE_TEAM_MEMBER_ERROR,
  payload: error,
});

const generateAttrTag = (publisherId: number, asin: string, url: string) => ({
  type: T.GENERATE_ATTR_TAG,
  payload: { publisherId, asin, url },
});

const generateAttrTagSuccess = () => ({
  type: T.GENERATE_ATTR_TAG_SUCCESS,
});

const generateAttrTagError = () => ({
  type: T.GENERATE_ATTR_TAG_ERROR,
});

const getPublisherGroups = () => ({
  type: T.GET_PUBLISHER_GROUPS,
});

const getPublisherGroupsSuccess = (data: PublishersSettingsModule.PublisherGroupData) => ({
  type: T.GET_PUBLISHER_GROUPS_SUCCESS,
  payload: data,
});

const getPublisherGroupsError = (error: Error) => ({
  type: T.GET_PUBLISHER_GROUPS_ERROR,
  payload: error,
});

const getTeam = (payload?: number) => ({
  type: T.GET_TEAM,
  payload,
});

const getTeamSuccess = (data: Array<PublishersSettingsModule.UserTeamData>) => ({
  type: T.GET_TEAM_SUCCESS,
  payload: { data },
});

const getTeamError = (error: Error) => ({
  type: T.GET_TEAM_ERROR,
  payload: error,
});

const getClients = () => ({
  type: T.GET_CLIENTS,
});

const getClientsSuccess = (data: Array<PublishersSettingsModule.UserFormData>) => ({
  type: T.GET_CLIENTS_SUCCESS,
  payload: { data },
});

const getClientsError = (error: Error) => ({
  type: T.GET_CLIENTS_ERROR,
  payload: error,
});

const patchTeamUser = (payload: PublishersSettingsModule.PatchUserTeam, onSuccess: () => void) => ({
  type: T.PATCH_TEAM_USER,
  payload,
  meta: { onSuccess },
});

const patchTeamUserSuccess = (payload: PublishersSettingsModule.PatchUserTeam) => ({
  type: T.PATCH_TEAM_USER_SUCCESS,
  payload,
});

const patchTeamUserError = (error: Error) => ({
  type: T.PATCH_TEAM_USER_ERROR,
  payload: error,
});

const cancelTeamUser = (payload: string, onSuccess: () => void) => ({
  type: T.CANCEL_TEAM_USER,
  payload,
  meta: { onSuccess },
});

const cancelTeamUserSuccess = () => ({
  type: T.CANCEL_TEAM_USER_SUCCESS,
});

const cancelTeamUserError = (error: Error) => ({
  type: T.CANCEL_TEAM_USER_ERROR,
  payload: error,
});

const resendTeamUser = (payload: string, onSuccess: () => void) => ({
  type: T.RESEND_TEAM_USER,
  payload,
  meta: { onSuccess },
});

const resendTeamUserSuccess = () => ({
  type: T.RESEND_TEAM_USER_SUCCESS,
});

const resendTeamUserError = (error: Error) => ({
  type: T.RESEND_TEAM_USER_ERROR,
  payload: error,
});

export const publishersSettingsActions = {
  getMainPublisherInfo,
  getMainPublisherInfoSuccess,
  getMainPublisherInfoError,
  patchMainPublisherCategories,
  patchMainPublisherCategoriesSuccess,
  patchMainPublisherCategoriesError,
  patchMainPublisherInfo,
  patchMainPublisherInfoSuccess,
  patchMainPublisherInfoError,
  getPublisherTags,
  getPublisherTagsSuccess,
  getPublisherTagsError,
  changePublisherTagsPage,
  addPublisherTag,
  patchDefaultTag,
  setTrafficType,
  setUsed,
  resetFilters,
  deletePublisherTag,
  deletePublisherTagSuccess,
  deletePublisherTagError,
  getPublisherUrls,
  getPublisherUrlsSuccess,
  getPublisherUrlsError,
  changePublisherUrlsPage,
  changePublisherCrawler,
  getPublisherAffiliate,
  getPublisherAffiliateSuccess,
  getPublisherAffiliateError,
  patchPublisherAffiliate,
  patchPublisherAffiliateSuccess,
  patchPublisherAffiliateError,
  getPublisherTeam,
  getPublisherTeamSuccess,
  getPublisherTeamError,
  getWordpressPlugin,
  getWordpressPluginSuccess,
  getWordpressPluginError,
  getIntegrationLink,
  getIntegrationLinkError,
  getIntegrationsList,
  getIntegrationsListSuccess,
  getIntegrationsListError,
  deleteIntegrationFromList,
  deleteIntegrationFromListSuccess,
  deleteIntegrationFromListError,
  getRateCards,
  getRateCardsSuccess,
  getRateCardsError,
  setRateCardsSelected,
  patchRateCards,
  patchRateCardsSuccess,
  patchRateCardsError,
  postAffiliateReport,
  postAffiliateReportSuccess,
  postAffiliateReportError,
  getAffiliateReportDownload,
  getAffiliateReportDownloadSuccess,
  getAffiliateReportDownloadError,
  getAffiliateReportHistory,
  getAffiliateReportHistorySuccess,
  getAffiliateReportHistoryError,
  sendInvitation,
  sendInvitationSuccess,
  sendInvitationError,
  deleteTeamMember,
  deleteTeamMemberSuccess,
  deleteTeamMemberError,
  generateAttrTag,
  generateAttrTagSuccess,
  generateAttrTagError,
  getPendingInvitations,
  getPendingInvitationsSuccess,
  getPendingInvitationsError,
  getPublisherGroups,
  getPublisherGroupsSuccess,
  getPublisherGroupsError,
  getTeam,
  getTeamSuccess,
  getTeamError,
  getClients,
  getClientsSuccess,
  getClientsError,
  patchTeamUser,
  patchTeamUserSuccess,
  patchTeamUserError,
  cancelTeamUser,
  cancelTeamUserSuccess,
  cancelTeamUserError,
  resendTeamUser,
  resendTeamUserSuccess,
  resendTeamUserError,
};
