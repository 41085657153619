import { GetRequestParams, getDataListActions } from 'common/store/actions/data-list';
import { mavPlusActionTypes as T, pubMaverickTableActionTypes } from './action-types';

const getPubMavPerformanceExport = (params: GetRequestParams) => ({
  type: T.GET_PUB_MAV_PERFORMANCE_EXPORTS,
  payload: { params },
});

const getPubMavPerformanceExportSuccess = (payload: string) => ({
  type: T.GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS,
  payload,
});

const getPubMavPerformanceExportError = (error: Error) => ({
  type: T.GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR,
  payload: error,
});

export const pubMaverickTableAction = getDataListActions(pubMaverickTableActionTypes);

export const mavPlusActions = {
  getPubMavPerformanceExport,
  getPubMavPerformanceExportSuccess,
  getPubMavPerformanceExportError,
};
