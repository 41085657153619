import { Box, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { TableContainerWithLoadingGrow } from 'common/ui/table-container-with-loading/table-container-with-loading-grow';
import { ReactElement, MouseEvent } from 'react';
import { Styles } from 'common/types';
import { EmptyChart } from 'common/ui/empty-chart';
import { theme } from 'common/constants/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AdminMavRow } from '../admin-mav-row';
// import { MavChartArea } from '../mav-chart-area';
import {
  selectPubMaverickTable,
  selectPubMaverickTableAmount,
  selectPubMaverickTableLimit,
  selectPubMaverickTableLoading,
  selectPubMaverickTablePage,
} from '../../store/selectors';
import { pubMaverickTableAction } from '../../store/actions';
import { DailyValues } from '../../types';
import { SumRow } from '../admin-mav-row/sum-row';
import { MavChartArea } from '../mav-chart-area';

const getStyles = (): Styles => ({
  tableHead: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 3 },
  container: {
    marginTop: 3,
    backgroundColor: '#fff',
    borderRadius: '20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '600px',
  },
  leftTableHead: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1.5,
    flexGrow: 1,
  },
  cell: { whiteSpace: 'nowrap' },
  tableWrapper: { flexGrow: 1 },
  exportLoading: { color: theme.palette.background.paper, mr: 1 },
  rightHeadSide: {
    display: 'flex',
    alignItems: 'center',
  },
  exportBttn: {
    marginLeft: 1,
  },
  emptyDataContainer: { alignSelf: 'center', margin: 'auto 0' },
});

interface Props {
  breakdown: string;
  selectedDaily: DailyValues;
}

export function MavPlusTable({ breakdown, selectedDaily }: Props): ReactElement {
  const dispatch = useDispatch();
  const styles = getStyles();

  const amount = useSelector(selectPubMaverickTableAmount);
  const page = useSelector(selectPubMaverickTablePage);
  const limit = useSelector(selectPubMaverickTableLimit);
  const loading = useSelector(selectPubMaverickTableLoading);
  const pubMaverickPerformance = useSelector(selectPubMaverickTable);

  const handlePageChange = (_: MouseEvent<HTMLButtonElement>, page: number) => {
    dispatch(pubMaverickTableAction.changePage(page));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(pubMaverickTableAction.changeLimit(parseInt(event.target.value, 10)));
    dispatch(pubMaverickTableAction.changePage(0));
  };

  return (
    <>
      {selectedDaily === DailyValues.DAILY && <MavChartArea />}
      <Box sx={styles.container}>
        {pubMaverickPerformance?.length || loading ? (
          <TableContainerWithLoadingGrow minHeight="unset" loading={loading} round={false}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.cell}>ASIN</TableCell>
                  <TableCell sx={styles.cell}>Url</TableCell>
                  <TableCell sx={styles.cell}>DPV</TableCell>
                  <TableCell sx={styles.cell}>ATC</TableCell>
                  <TableCell sx={styles.cell}>Units sold</TableCell>
                  <TableCell sx={styles.cell}>GMV</TableCell>
                  {selectedDaily === DailyValues.DAILY && <TableCell sx={styles.cell}>Day</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                <SumRow data={pubMaverickPerformance} />
                {pubMaverickPerformance?.map((r, i) => (
                  <AdminMavRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    r={r}
                    breakdown={breakdown}
                    selectedDaily={selectedDaily}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainerWithLoadingGrow>
        ) : (
          <Box sx={styles.emptyDataContainer}>
            <EmptyChart description="Your mav+ data will appear here" image="/assets/images/no-earnings-chart.svg" />
          </Box>
        )}

        {(pubMaverickPerformance?.length || loading) && (
          <TablePagination
            component="div"
            count={amount}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </>
  );
}
