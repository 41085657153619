/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { ScheduledTagsImportLocalStorage } from 'modules/publishers-affiliate-tags/service/tags-import.service';
import { publishersTagsActions } from 'modules/publishers-affiliate-tags/store/actions/publisher-tags-action';
import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { selectPublishersTagsLoading } from 'modules/publishers-affiliate-tags/store/selectors';
import { TagsImportInfo } from './tags-import-info';

interface TagsImportModalProps {
  open: boolean;
  onClose: () => void;
  publisher?: PublishersAffiliateTagsModule.ExtendedPublisherTags;
  publisherTagsImport?: ScheduledTagsImportLocalStorage;
}

const styles: Styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    width: '20%',
    padding: '20px 0',
    lineHeight: '0',
    color: 'white',
    backgroundColor: '#4288F0',
    marginTop: '10px',
  },
};

export function TagsImportModal({ open, onClose, publisher, publisherTagsImport }: TagsImportModalProps) {
  const dispatch = useDispatch();

  const loading = useSelector(selectPublishersTagsLoading);

  const [tagPrefix, setTagPrefix] = useState<string>(null);
  const [amount, setImportAmount] = useState<number>(null);

  const handleTagPrefixChange = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTagPrefix(e.target.value);
  }, 300);

  const handleImportAmountChange = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!/\d+$/.test(value) && value) {
      toast.error('Only numbers are allowed');
      return;
    }

    setImportAmount(parseInt(value, 10));
  }, 300);

  useEffect(() => {
    if (!publisherTagsImport || !open) {
      return;
    }

    dispatch(
      publishersTagsActions.getTagsImportStatus(publisherTagsImport.publisherName, publisherTagsImport.executionName)
    );
  }, [open]);

  const handleImportClick = () => {
    dispatch(
      publishersTagsActions.scheduleTagsImport({
        publisher_id: publisher.id,
        publisher_name: publisher.publisher_name,
        tag_prefix: tagPrefix,
        tags_amount: amount,
      })
    );
  };

  return (
    <Modal open={open} title="Tags Import" maxWidth="sm" onClose={onClose}>
      {publisherTagsImport && <TagsImportInfo publisherTagsImport={publisherTagsImport} />}
      <Box sx={styles.formContainer}>
        <Box sx={styles.field}>
          <Typography>Tag prefix</Typography>
          <TextField onChange={handleTagPrefixChange} />
        </Box>
        <Box sx={styles.field}>
          <Typography>Amount</Typography>
          <TextField onChange={handleImportAmountChange} />
        </Box>
        <Button color="primary" sx={styles.button} variant="contained" disabled={loading} onClick={handleImportClick}>
          Import
        </Button>
      </Box>
    </Modal>
  );
}
