import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Box, Link, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { selectRootDemo } from 'common/store/selectors';
import { Styles } from 'common/types';
import { DailyValues, MavBreakdowns, PubMaverickPerformance } from 'modules/mav-plus/constants';

interface StylesProps {
  inStock?: boolean;
  isDemoMode?: boolean;
}

const getStyles = ({ inStock, isDemoMode }: StylesProps): Styles => ({
  detailsBtn: {
    width: '115px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    border: '1px solid #C2DEEB',
    borderRadius: '12px',
    cursor: 'pointer',
    color: '#6F8490',
  },
  detailsText: { marginRight: '5px' },
  hightStatus: {
    borderRadius: '20px',
    backgroundColor: '#e15a5322',
    color: '#E15953',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '78px',
    height: '24px',
  },
  mediumStatus: {
    borderRadius: '20px',
    backgroundColor: '#FFAE3C22',
    color: '#FFAE3C',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '78px',
    height: '24px',
  },
  lowStatus: {
    borderRadius: '20px',
    backgroundColor: '#88CEF522',
    color: '#88CEF5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '78px',
    height: '24px',
  },
  valueWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  valueWithStatus: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 1,
  },
  upValue: {
    color: '#62D294',
  },
  downValue: {
    color: '#E15953',
  },
  nameWrapper: { display: 'flex', alignItems: 'center', gap: 1.5, cursor: 'pointer' },
  name: { fontSize: 14, width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
  cpc: { width: 70 },
  brand: { fontSize: 12, width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
  link: {
    color: '#4288F0',
    textDecoration: 'underline',
    fontSize: 14,
    width: 'auto',
    cursor: 'pointer',
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
  stock: {
    color: inStock ? '#62D294' : 'rgb(246, 81, 71)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: inStock ? 10 : 8,
    width: 55,
  },
  statusCircle: {
    width: '3px',
    height: '3px',
    borderRadius: '50%',
    backgroundColor: inStock ? '#62D294' : 'rgb(246, 81, 71)',
    mr: '4px',
  },
  asinContainer: { cursor: 'pointer' },
  asinImage: { width: 55, height: 55 },
});

interface Props {
  r: PubMaverickPerformance;
  breakdown: string;
  selectedDaily: DailyValues;
}

export function AdminMavRow({ r, breakdown, selectedDaily }: Props): ReactElement {
  const isDemoMode = useSelector(selectRootDemo);

  const styles = getStyles({ inStock: Boolean(r?.in_stock), isDemoMode });
  const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

  const url = r?.url?.replaceAll('https://', '').replaceAll('http://', '');

  const renderDynamicColumns = () => {
    switch (breakdown) {
      case MavBreakdowns.PUBLISHER:
        return <TableCell sx={styles.cell}>{r.publisher_name ?? '-'}</TableCell>;
      case MavBreakdowns.BRAND:
        return <TableCell sx={styles.cell}>{r.brand_name ?? '-'}</TableCell>;
      case MavBreakdowns.ASIN:
        return (
          <>
            <TableCell>
              <Box sx={styles.asinContainer} onClick={() => window.open(`https://amazon.com/dp/${r.asin}`)}>
                <Box component="img" sx={styles.asinImage} src={r.img_small || r.img_large} />
                <Typography>{r.asin ?? '-'}</Typography>
                {r.brand_name && (
                  <Tooltip title={r.brand_name} placement="top">
                    <Typography sx={styles.brand}>{r.brand_name}</Typography>
                  </Tooltip>
                )}
                {Boolean(r.asin) && (
                  <Box sx={styles.stock}>
                    <Box sx={styles.statusCircle} />
                    {r?.in_stock ? 'In Stock' : 'Out of Stock'}
                  </Box>
                )}
              </Box>
            </TableCell>
            <TableCell>
              {r?.url ? (
                <Link href={`//${url}`} sx={styles.link} target="_blank" rel="noreferrer" underline="always">
                  {url}
                </Link>
              ) : (
                '-'
              )}
            </TableCell>
          </>
        );
      default:
        return <TableCell sx={styles.cell}>{r.publisher_name ?? '-'}</TableCell>;
    }
  };

  return (
    <TableRow>
      {renderDynamicColumns()}
      <TableCell>{r.amz_total_dpv ?? 0}</TableCell>
      <TableCell>{r.amz_total_atc ?? 0}</TableCell>
      <TableCell>{r.amz_unit_sold ?? 0}</TableCell>
      <TableCell>
        <Box sx={styles.valueWrapper}>{money.format(r.amz_product_sales ?? 0)}</Box>
      </TableCell>
      {selectedDaily === DailyValues.DAILY && <TableCell>{r?.date ?? '-'}</TableCell>}
    </TableRow>
  );
}
