import { TFunction } from 'i18next';
import HomeIcon from '@mui/icons-material/Home';
import SpeedIcon from '@mui/icons-material/Speed';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import SettingsIcon from '@mui/icons-material/Settings';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MouseIcon from '@mui/icons-material/Mouse';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ViewListIcon from '@mui/icons-material/ViewList';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import InventoryIcon from '@mui/icons-material/Inventory';
import { SvgIconTypeMap } from '@mui/material';
import { userService } from 'common/services/user.service';
import { UploadFileRounded } from '@mui/icons-material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { onboardingTargets } from './onboarding';
import { PERMISSIONS } from './permissions';

export interface NavItem {
  title: string;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & {
    muiName: string;
  };
  target?: string;
  nested?: Array<NavItem>;
  isAvailable: boolean;
  newVersion?: {
    path: string;
    title: string;
  };
}

interface NavConfig {
  t: TFunction;
  isGainz?: boolean;
  isGainzOnly?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getNavConfig = ({ t, isGainz, isGainzOnly }: NavConfig): Array<NavItem> => [
  {
    title: 'Overview',
    path: '/',
    icon: HomeIcon,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]) && !isGainzOnly,
    target: onboardingTargets.overview,
  },
  {
    title: 'Tracking Overview',
    icon: TrendingUpIcon,
    isAvailable: !isGainzOnly,
    target: onboardingTargets.trackingOverview,
    nested: [
      {
        title: 'Performance',
        path: '/content-overview/performance',
        icon: DataSaverOffIcon,
        isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
      },
      {
        title: 'Paid conversions',
        path: '/conversions',
        icon: MouseIcon,
        isAvailable: userService.hasAccess([PERMISSIONS.GET_STATISTICS]),
      },
    ],
  },
  // {
  //   title: 'Content Overview',
  //   icon: ContentPasteSearchIcon,
  //   isAvailable: true,
  //   nested: [
  //     {
  //       title: 'Product Placement',
  //       icon: FindReplaceIcon,
  //       path: '/content-overview/replace',
  //       isAvailable: true,
  //     },
  //   ],
  // },

  {
    title: 'MaverickX',
    icon: FitnessCenterIcon,
    isAvailable: isGainz,
    target: onboardingTargets.affiliateGainz,
    nested: [
      {
        title: 'Attribution',
        path: '/mavp',
        icon: SpeedIcon,
        isAvailable: isGainz,
      },
      {
        title: 'Recommended ASINs',
        icon: FindReplaceIcon,
        path: '/affiliate-gainz/recommend',
        isAvailable: isGainz,
      },
      // {
      //   title: 'Rev by page',
      //   icon: DataSaverOffIcon,
      //   path: '/revenue-by-page',
      //   isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS]),
      // },
    ],
  },
  {
    title: 'Settings',
    icon: SettingsIcon,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHER, PERMISSIONS.EDIT_PUBLISHER]),
    target: onboardingTargets.publisherSettings,
    nested: [
      {
        title: 'Publisher',
        icon: DisplaySettingsIcon,
        path: 'publisher/settings/',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHER, PERMISSIONS.EDIT_PUBLISHER]),
      },
      {
        title: 'URL Management',
        icon: ManageSearchIcon,
        path: '/content-overview/management',
        isAvailable: !isGainzOnly,
      },
    ],
  },
];

export const getAdminNavConfig = () => [
  {
    title: 'Admin Panel',
    icon: AdminPanelSettingsIcon,
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
    nested: [
      {
        title: 'View Publishers',
        icon: ViewListIcon,
        path: 'settings/publishers/',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
      },
      {
        title: 'Publishers Affiliate Tags',
        icon: ViewListIcon,
        path: '/settings/publishers-tags',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
      },
      {
        title: 'Parent Publishers',
        icon: FormatListBulletedIcon,
        path: 'settings/parent-publishers/',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
      },
      {
        title: 'Asin Finder',
        icon: ContentPasteSearchIcon,
        path: 'tools/asin-finder',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
      },
      {
        title: 'PubX Reports',
        icon: InventoryIcon,
        path: '/tools/pubx-reports',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
      },
      {
        title: 'Amazon Affiliate Reports',
        icon: UploadFileRounded,
        path: '/tools/amazon-affiliate-reports',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
      },
      {
        title: 'Conversions Boost',
        icon: MonetizationOnIcon,
        path: '/tools/asins-conversions-boost',
        isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
      },
    ],
  },
];

export const getDemoAppAdminNavConfig = () => [
  {
    title: 'View Publishers',
    icon: ViewListIcon,
    path: 'settings/publishers/',
    isAvailable: userService.hasAccess([PERMISSIONS.GET_PUBLISHERS, PERMISSIONS.EDIT_PUBLISHER]),
  },
];
