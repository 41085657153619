import { ApiService } from 'common/services';
import { parseFilters } from 'common/utils/parse-filters';

class MavPlusApiService extends ApiService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPublisherMaverickPerformance = (filters: any) => {
    return this.get('/api/ads/admin/publisher-mavplus', {
      params: parseFilters(filters),
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPubMavPerformanceExports = (filters: any) => {
    return this.get('/api/ads/admin/publisher-mavplus/export', { params: parseFilters(filters) });
  };

  getExportStatus = (id: string) => {
    return this.get(`/api/export/${id}/status`);
  };
}

export const mavPlusApi = new MavPlusApiService(process.env.REACT_APP_API_URL);
