import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { ScheduledTagsImportLocalStorage } from 'modules/publishers-affiliate-tags/service/tags-import.service';

interface TagsImportInfoProps {
  publisherTagsImport: Partial<ScheduledTagsImportLocalStorage>;
}

const styles: Styles = {
  lastImportInfo: {
    display: 'flex',
    marginBottom: '40px',
  },
};

export function TagsImportInfo({ publisherTagsImport }: TagsImportInfoProps) {
  return (
    <Box sx={styles.lastImportInfo}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4} sx={{ fontSize: '15px' }}>
              Last Tags Import
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tag Prefix</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Completed At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{publisherTagsImport.tagPrefix}</TableCell>
            <TableCell>{publisherTagsImport.amount}</TableCell>
            <TableCell>{publisherTagsImport.state}</TableCell>
            <TableCell>{publisherTagsImport.completionTime}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
