const SCHEDULED_TAGS_IMPORT_KEY = 'scheduled_tags_import';

export type ScheduledTagsImportLocalStorage = PublishersAffiliateTagsModule.PublishersTagsStore['scheduledTagsImport'] &
  Partial<PublishersAffiliateTagsModule.TagsImportStatusResponse>;

const updateScheduledTagsImportItems = (scheduledItem: ScheduledTagsImportLocalStorage) => {
  const scheduledImports = JSON.parse(localStorage.getItem(SCHEDULED_TAGS_IMPORT_KEY) || '[]');

  const existingItemIndex = scheduledImports.findIndex(x => x.publisherName === scheduledItem.publisherName);

  if (existingItemIndex >= 0) {
    scheduledImports[existingItemIndex] = {
      ...scheduledImports[existingItemIndex],
      ...(scheduledItem.executionName ? { executionName: scheduledItem.executionName } : {}),
      ...(scheduledItem.tagPrefix ? { tagPrefix: scheduledItem.tagPrefix } : {}),
      ...(scheduledItem.amount ? { amount: scheduledItem.amount } : {}),
      state: scheduledItem.state,
      createTime: scheduledItem.createTime,
      completionTime: scheduledItem.completionTime,
    };
  } else {
    scheduledImports.push(scheduledItem);
  }

  localStorage.setItem(SCHEDULED_TAGS_IMPORT_KEY, JSON.stringify(scheduledImports));
};

const getScheduledTagsImports = () => {
  const scheduledImports = JSON.parse(localStorage.getItem(SCHEDULED_TAGS_IMPORT_KEY) || '[]');

  return scheduledImports as ScheduledTagsImportLocalStorage[];
};

const getScheduledTagsImportByPublisher = (publisherName: string) => {
  const scheduledImport = getScheduledTagsImports().find(x => x.publisherName === publisherName);

  return scheduledImport;
};

export const TagsImportService = {
  updateScheduledTagsImportItems,
  getScheduledTagsImports,
  getScheduledTagsImportByPublisher,
};
