export interface PubMaverickPerformance {
  adgroup_id: number;
  adgroup_name_url_asin: string;
  amz_clicks: number;
  amz_total_atc: number;
  amz_total_dpv: number;
  amz_product_sales: number;
  amz_unit_sold: number;
  urlId: string;
  asin: string;
  date?: string;
  url?: string;
  publisher_name?: string;
  brand_name?: string;
  in_stock?: number;
}

export enum DailyValues {
  DAILY = 1,
  COMBINED = 0,
}
