/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Banner } from 'modules/parent-publisher-settings/components/banner';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { selectGlobalFilters } from 'common/store/selectors';
import { IStatisticsQueryString } from 'common/ui/base-filters/types';
import { PublishersTagsTable } from './components/publishers-tags-table';
import { publishersTagsActions } from './store/actions/publisher-tags-action';
import {
  selectCurrentPublishersTagsPage,
  selectLastScheduledImport,
  selectLastTagsImportStatus,
  selectPublishersTagsSort,
} from './store/selectors';
import { TagsImportModal } from './components/tags-import-modal';
import { ScheduledTagsImportLocalStorage, TagsImportService } from './service/tags-import.service';

export function PublisherAffiliateTags(): ReactElement {
  const dispatch = useDispatch();

  const { search: initialSearch } = useSelector(selectGlobalFilters);
  const currentPage = useSelector(selectCurrentPublishersTagsPage);
  const scheduledTagsImport = useSelector(selectLastScheduledImport);
  const scheduledTagsImportStatus = useSelector(selectLastTagsImportStatus);
  const sort = useSelector(selectPublishersTagsSort);

  const [search, setSearch] = useState<string>(initialSearch);
  const [isModalOpen, setModalOpen] = useState(false);
  const [targetPublisher, setTargetPublisher] = useState<PublishersAffiliateTagsModule.ExtendedPublisherTags>(null);
  const [publisherTagsImport, setPublisherTagsImport] = useState<ScheduledTagsImportLocalStorage>(
    TagsImportService.getScheduledTagsImportByPublisher(targetPublisher?.publisher_name)
  );

  const tableFilters: IStatisticsQueryString = useMemo(
    () => ({
      limit: 30,
      offset: search ? 0 : currentPage * 30,
      publisher_name: search,
      sort,
    }),
    [currentPage, search, sort]
  );

  const handlePublisherSearch = (value: string) => setSearch(value);

  const handleTagsImportClick = (publisher: PublishersAffiliateTagsModule.ExtendedPublisherTags) => {
    setTargetPublisher(publisher);
    setPublisherTagsImport(TagsImportService.getScheduledTagsImportByPublisher(publisher.publisher_name));
    setModalOpen(true);
  };

  const handleTagsImportClose = () => {
    setTargetPublisher(null);
    setPublisherTagsImport(null);
    setModalOpen(false);
  };

  const handleRefreshTable = () => {
    dispatch(publishersTagsActions.getPublishersTags(tableFilters));
  };

  useEffect(() => {
    if (!scheduledTagsImport) {
      return;
    }

    TagsImportService.updateScheduledTagsImportItems({ ...scheduledTagsImport });
    setPublisherTagsImport(TagsImportService.getScheduledTagsImportByPublisher(targetPublisher?.publisher_name));
  }, [scheduledTagsImport]);

  useEffect(() => {
    if (!scheduledTagsImportStatus) {
      return;
    }

    TagsImportService.updateScheduledTagsImportItems({ ...scheduledTagsImportStatus });
    setPublisherTagsImport(TagsImportService.getScheduledTagsImportByPublisher(targetPublisher?.publisher_name));
  }, [scheduledTagsImportStatus]);

  const debouncedPublishers = useDebouncedCallback(() => {
    dispatch(publishersTagsActions.getPublishersTags(tableFilters));
  }, 300);

  useEffect(() => {
    debouncedPublishers();
  }, [tableFilters]);

  return (
    <>
      <Banner
        title="Publishers Affiliate Tags"
        options={{ search: true }}
        searchValue={search}
        handleSearchChange={handlePublisherSearch}
      />
      <PublishersTagsTable onTagsImportClick={handleTagsImportClick} handleRefreshTable={handleRefreshTable} />
      <TagsImportModal
        open={isModalOpen}
        publisherTagsImport={publisherTagsImport}
        publisher={targetPublisher}
        onClose={handleTagsImportClose}
      />
    </>
  );
}
