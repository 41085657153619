import { SelectOption } from 'common/ui/select-field/select-field';

export enum MavBreakdowns {
  PUBLISHER = 'publisher',
  ASIN = 'asin',
  BRAND = 'brand',
}

export enum DailyValues {
  DAILY = 1,
  COMBINED = 0,
}

export const dailyOptions = [
  {
    title: 'Daily',
    value: DailyValues.DAILY,
  },
  {
    title: 'Combined',
    value: DailyValues.COMBINED,
  },
];

export const stockOptions: Array<SelectOption> = [
  {
    title: 'All',
    value: -1,
  },
  {
    title: 'Out of Stock',
    value: 0,
  },
  {
    title: 'In Stock',
    value: 1,
  },
];

export const sourceOptions: Array<SelectOption> = [
  {
    title: 'All',
    value: -1,
  },
  {
    title: 'Organic only',
    value: 0,
  },
  {
    title: 'Ad only',
    value: 1,
  },
];

export interface PubMaverickPerformance {
  adgroup_id: number;
  adgroup_name_url_asin: string;
  amz_clicks: number;
  amz_total_atc: number;
  amz_total_dpv: number;
  amz_product_sales: number;
  amz_unit_sold: number;
  urlId: string;
  asin: string;
  img_large?: string;
  img_small?: string;
  date?: string;
  url?: string;
  publisher_name?: string;
  brand_name?: string;
  in_stock?: number;
}
