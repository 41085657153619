import { Action } from 'common/interfaces/root';
import { publishersSettingsActionTypes as T } from '../actions';

const initState: PublishersSettingsModule.FiltersStore = {
  group: '',
  gainz: '',
};

export default (
  state: PublishersSettingsModule.FiltersStore = initState,
  { type, payload }: Action
): PublishersSettingsModule.FiltersStore => {
  switch (type) {
    case T.SET_GROUP:
      return { ...state, group: payload };
    case T.SET_GAINZ:
      return { ...state, gainz: payload };
    case T.RESET_FILTERS:
      return initState;

    default:
      return state;
  }
};
