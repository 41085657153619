export enum SETTINGS_NAV {
  publishers = 'publishers',
  tags = 'tags',
}

export enum PUBLISHER_SETTINGS_NAV {
  settings = 'settings',
  tags = 'tags',
  urls = 'urls',
  embed = 'embed',
  affiliate = 'affiliate',
  conversion = 'conversion',
  rateCard = 'rateCard',
  team = 'team',
  integrations = 'integrations',
  new_team = 'new-team',
}

export enum URL_MANAGEMENT_NAV {
  sitemaps = 'sitemaps',
  urls = 'urls',
  crawler = 'crawler',
}

export enum ADDED_FOUNDED_FILTER {
  ALL = 0,
  NEW = 1,
  EXISTING = 2,
}

export enum CountryCode {
  US = 'US',
  GB = 'GB',
  DE = 'DE',
  FR = 'FR',
  JP = 'JP',
  CA = 'CA',
  CN = 'CN',
  IT = 'IT',
  ES = 'ES',
  IN = 'IN',
  BR = 'BR',
  MX = 'MX',
  AU = 'AU',
  AE = 'AE',
  SG = 'SG',
  NL = 'NL',
  SA = 'SA',
  SE = 'SE',
  PL = 'PL',
  BE = 'BE',
}

type AmazonAffiliateRegionOptions = Record<CountryCode, { label: string; value: string }>;

export const amazonAffiliateRegionOptions: AmazonAffiliateRegionOptions = {
  [CountryCode.US]: { label: 'United States', value: 'https://affiliate-program.amazon.com/' },
  [CountryCode.GB]: { label: 'United Kingdom', value: 'https://affiliate-program.amazon.co.uk/' },
  [CountryCode.DE]: { label: 'Germany', value: 'https://partnernet.amazon.de/' },
  [CountryCode.FR]: { label: 'France', value: 'https://partenaires.amazon.fr/' },
  [CountryCode.JP]: { label: 'Japan', value: 'https://affiliate.amazon.co.jp/' },
  [CountryCode.CA]: { label: 'Canada', value: 'https://associates.amazon.ca/' },
  [CountryCode.CN]: { label: 'China', value: 'https://associates.amazon.cn/' },
  [CountryCode.IT]: { label: 'Italy', value: 'https://programma-affiliazione.amazon.it/' },
  [CountryCode.ES]: { label: 'Spain', value: 'https://afiliados.amazon.es/' },
  [CountryCode.IN]: { label: 'India', value: 'https://affiliate-program.amazon.in/' },
  [CountryCode.BR]: { label: 'Brazil', value: 'https://associados.amazon.com.br/' },
  [CountryCode.MX]: { label: 'Mexico', value: 'https://afiliados.amazon.com.mx/' },
  [CountryCode.AU]: { label: 'Australia', value: 'https://affiliate-program.amazon.com.au/' },
  [CountryCode.AE]: { label: 'United Arab Emirates', value: 'https://affiliate-program.amazon.ae/' },
  [CountryCode.SG]: { label: 'Singapore', value: 'https://affiliate-program.amazon.sg/' },
  [CountryCode.NL]: { label: 'Netherlands', value: 'https://partnernet.amazon.nl/' },
  [CountryCode.SA]: { label: 'Saudi Arabia', value: 'https://affiliate-program.amazon.sa/' },
  [CountryCode.SE]: { label: 'Sweden', value: 'https://affiliate-program.amazon.se/' },
  [CountryCode.PL]: { label: 'Poland', value: 'https://affiliate-program.amazon.pl/' },
  [CountryCode.BE]: { label: 'Belgium', value: 'https://affiliate-program.amazon.com.be/' },
};
