import { getDataListActionTypes } from 'common/store/actions/data-list';

const PUB_MAVERICK_TABLE_MODULE = '[mav-plus:table]';
const PUB_MAVERICK_EXPORT_MODULE = '[mav-plus:export]';

const GET_PUB_MAV_PERFORMANCE_EXPORTS = `${PUB_MAVERICK_EXPORT_MODULE} GET_PUB_MAV_PERFORMANCE_EXPORTS`;
const GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS = `${PUB_MAVERICK_EXPORT_MODULE} GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS`;
const GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR = `${PUB_MAVERICK_EXPORT_MODULE} GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR`;

export const pubMaverickTableActionTypes = getDataListActionTypes(PUB_MAVERICK_TABLE_MODULE);

export const mavPlusActionTypes = {
  GET_PUB_MAV_PERFORMANCE_EXPORTS,
  GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS,
  GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR,
};
