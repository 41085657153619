import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import toast from 'react-hot-toast';
import { all, call, delay, put, takeEvery } from 'redux-saga/effects';
import { mavPlusApi } from 'modules/mav-plus/services/pub-mav-plus.service';
import { mavPlusActionTypes, mavPlusActions, pubMaverickTableAction, pubMaverickTableActionTypes } from '../actions';

// eslint-disable-next-line consistent-return
function* checkExportStatus(id: string): SagaIterator<string> {
  let status = '';

  while (status !== 'success') {
    const { data } = yield call(mavPlusApi.getExportStatus, id);

    switch (data.status) {
      case 'pending':
        status = data.status;
        yield delay(1000);
        break;
      case 'success':
        status = data.status;
        return data.link;
      case 'failed':
        status = data.status;
        throw new Error('export failed');
      default:
        throw new Error('export failed');
    }
  }
}

function* getPublisherMavPerformance({ payload }: Action) {
  try {
    const { data } = yield call(mavPlusApi.getPublisherMaverickPerformance, payload);
    yield put(pubMaverickTableAction.getSuccess(data.results, data.count));
  } catch (err) {
    yield put(pubMaverickTableAction.getError(err as Error));
  }
}

function* getPubMavPerformanceExport({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(mavPlusApi.getPubMavPerformanceExports, payload.params);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(mavPlusActions.getPubMavPerformanceExportSuccess(link));
  } catch (err) {
    toast.error('Error while exporting data!');
    yield put(mavPlusActions.getPubMavPerformanceExportError(err as Error));
  }
}

export function* mavPlusSaga(): SagaIterator {
  yield all([
    takeEvery(pubMaverickTableActionTypes.GET, getPublisherMavPerformance),
    takeEvery(mavPlusActionTypes.GET_PUB_MAV_PERFORMANCE_EXPORTS, getPubMavPerformanceExport),
  ]);
}
