import { Box, TableCell, TableRow } from '@mui/material';
import { Styles } from 'common/types';
import { PubMaverickPerformance } from 'modules/mav-plus/types';
import { ReactElement } from 'react';

const styles: Styles = {
  detailsBtn: {
    width: '115px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    border: '1px solid #C2DEEB',
    borderRadius: '12px',
    cursor: 'pointer',
    color: '#6F8490',
  },
  detailsText: { marginRight: '5px' },
  hightStatus: {
    borderRadius: '20px',
    backgroundColor: '#e15a5322',
    color: '#E15953',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '78px',
    height: '24px',
  },
  mediumStatus: {
    borderRadius: '20px',
    backgroundColor: '#FFAE3C22',
    color: '#FFAE3C',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '78px',
    height: '24px',
  },
  lowStatus: {
    borderRadius: '20px',
    backgroundColor: '#88CEF522',
    color: '#88CEF5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '78px',
    height: '24px',
  },
  valueWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  valueWithStatus: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 1,
  },
  upValue: {
    color: '#62D294',
  },
  downValue: {
    color: '#E15953',
  },
  nameWrapper: { display: 'flex', alignItems: 'center', gap: 1.5, cursor: 'pointer' },
  name: { fontSize: 14, width: 130, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
  cpc: { width: 70 },
};

interface Props {
  data: PubMaverickPerformance[];
}

export function SumRow({ data }: Props): ReactElement {
  const money = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

  return (
    <TableRow>
      <TableCell>Sum of values</TableCell>
      <TableCell />
      <TableCell>
        <Box sx={styles.valueWrapper}>{data.reduce((sum, { amz_total_dpv }) => sum + amz_total_dpv, 0)}</Box>
      </TableCell>
      <TableCell>
        <Box sx={styles.valueWrapper}>{data.reduce((sum, { amz_total_atc }) => sum + amz_total_atc, 0)}</Box>
      </TableCell>
      <TableCell>
        <Box sx={styles.valueWrapper}>{data.reduce((sum, { amz_unit_sold }) => sum + amz_unit_sold, 0)}</Box>
      </TableCell>
      <TableCell>
        <Box sx={styles.valueWrapper}>
          {money.format(data.reduce((sum, { amz_product_sales }) => sum + amz_product_sales, 0))}
        </Box>
      </TableCell>
    </TableRow>
  );
}
