import { ReactElement } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { publishersSettingsActions } from 'modules/publishers-settings/store/actions';
import { PublisherFormContent } from 'modules/publishers-settings/components/publisher-modal/publisher-form-content';
import { PublisherAction } from 'modules/publishers-settings/components/publisher-modal/publisher-modal';

const getInitialValues = (publisher?: PublishersSettingsModule.Publisher): PublishersSettingsModule.PublisherForm => ({
  name: publisher?.name ?? '',
  domain: publisher?.domain ?? '',
  publishers_parents_id: publisher?.publishers_parents_id ?? null,
  categories: publisher?.categories ?? [],
  channels: publisher ? Object.values(publisher?.channels) : [],
  skm_tag_prefix: publisher?.skm_tag_prefix ?? null,
  imp_tag_prefix: publisher?.imp_tag_prefix ?? null,
  nrv_tag_prefix: publisher?.nrv_tag_prefix ?? null,
  publisher_id_aos: publisher?.publisher_id_aos ?? null,
  publisher_account_id: publisher?.publisher_account_id ?? null,
  gainz_active: publisher?.gainz_active ?? false,
  gainz_only: publisher?.gainz_only ?? false,
  commission_rate: publisher?.commission_rate ?? null,
});

interface Props {
  publisher?: PublishersSettingsModule.Publisher;
  action: PublisherAction;
  handleModalClose: () => void;
  isModalOpen: boolean;
}

export function PublisherForm({ publisher, action, handleModalClose, isModalOpen }: Props): ReactElement {
  const dispatch = useDispatch();

  const handlePublisherSubmit = (values: PublishersSettingsModule.PublisherForm) => {
    switch (action) {
      case PublisherAction.create:
        dispatch(
          publishersSettingsActions.createPublisher({
            ...values,
            ...(values.publishers_parents_id !== null && values.publishers_parents_id !== 'NEW'
              ? { publishers_parents_id: values.publishers_parents_id }
              : { publishers_parents_id: undefined }),
          })
        );
        break;
      case PublisherAction.edit:
        dispatch(
          publishersSettingsActions.editPublisher(
            {
              ...values,
              ...(values.publishers_parents_id !== null && values.publishers_parents_id !== 'NEW'
                ? { publishers_parents_id: values.publishers_parents_id }
                : { publishers_parents_id: undefined }),
              imp_tag_prefix: values.imp_tag_prefix === '' ? null : values.imp_tag_prefix,
              nrv_tag_prefix: values.nrv_tag_prefix === '' ? null : values.nrv_tag_prefix,
              skm_tag_prefix: values.skm_tag_prefix === '' ? null : values.skm_tag_prefix,
            },
            publisher.id
          )
        );
        break;
      default:
        break;
    }
    handleModalClose();
  };

  const handleSubmit = (values: PublishersSettingsModule.PublisherForm) => {
    handlePublisherSubmit(values);
  };

  return (
    <Formik initialValues={getInitialValues(publisher)} onSubmit={handleSubmit}>
      <PublisherFormContent handleModalClose={handleModalClose} isModalOpen={isModalOpen} />
    </Formik>
  );
}
