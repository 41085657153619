import { getDataListActionTypes } from 'common/store/actions/data-list';
import { getFiltersActionTypes } from 'common/store/actions/filters';

const PUBLISHERS_TABLE_MODULE = '[publishers:table]';
const PUBLISHERS_PARENTS_MODULE = '[publishers:parents]';

const GET_PUBLISHERS = `${PUBLISHERS_TABLE_MODULE} GET_PUBLISHERS`;
const GET_PUBLISHERS_SUCCESS = `${PUBLISHERS_TABLE_MODULE} GET_PUBLISHERS_SUCCESS`;
const GET_PUBLISHERS_ERROR = `${PUBLISHERS_TABLE_MODULE} GET_PUBLISHERS_ERROR`;

const CREATE_PUBLISHER = `${PUBLISHERS_TABLE_MODULE} CREATE_PUBLISHER`;
const CREATE_PUBLISHER_SUCCESS = `${PUBLISHERS_TABLE_MODULE} CREATE_PUBLISHER_SUCCESS`;
const CREATE_PUBLISHER_ERROR = `${PUBLISHERS_TABLE_MODULE} CREATE_PUBLISHER_ERROR`;

const EDIT_PUBLISHER = `${PUBLISHERS_TABLE_MODULE} EDIT_PUBLISHER`;
const EDIT_PUBLISHER_SUCCESS = `${PUBLISHERS_TABLE_MODULE} EDIT_PUBLISHER_SUCCESS`;
const EDIT_PUBLISHER_ERROR = `${PUBLISHERS_TABLE_MODULE} EDIT_PUBLISHER_ERROR`;

const DELETE_PUBLISHER = `${PUBLISHERS_TABLE_MODULE} DELETE_PUBLISHER`;
const DELETE_PUBLISHER_SUCCESS = `${PUBLISHERS_TABLE_MODULE} DELETE_PUBLISHER_SUCCESS`;
const DELETE_PUBLISHER_ERROR = `${PUBLISHERS_TABLE_MODULE} DELETE_PUBLISHER_ERROR`;

const GET_PUBLISHER_BLOCKED_BRANDS = `${PUBLISHERS_TABLE_MODULE} GET_BLOCKED_BRANDS`;
const GET_PUBLISHER_BLOCKED_BRANDS_SUCCESS = `${PUBLISHERS_TABLE_MODULE} GET_BLOCKED_BRANDS_SUCCESS`;
const GET_PUBLISHER_BLOCKED_BRANDS_ERROR = `${PUBLISHERS_TABLE_MODULE} GET_BLOCKED_BRANDS_ERROR`;

const BLOCK_PUBLISHER_BRAND = `${PUBLISHERS_TABLE_MODULE} BLOCK_PUBLISHER_BRAND`;
const UNBLOCK_PUBLISHER_BRAND = `${PUBLISHERS_TABLE_MODULE} UNBLOCK_PUBLISHER_BRAND`;

const SET_GROUP = `${PUBLISHERS_TABLE_MODULE} SET_GROUP`;
const SET_GAINZ = `${PUBLISHERS_TABLE_MODULE} SET_GAINZ`;
const RESET_FILTERS = `${PUBLISHERS_TABLE_MODULE} RESET_FILTERS`;

const ADD_PUBLISHERS_PARENTS = `${PUBLISHERS_PARENTS_MODULE} ADD_PUBLISHERS_PARENTS`;
const DELETE_PUBLISHERS_PARENTS = `${PUBLISHERS_PARENTS_MODULE} DELETE_PUBLISHERS_PARENTS`;

export const publishersSettingsFiltersActionTypes = getFiltersActionTypes(PUBLISHERS_TABLE_MODULE);
export const publishersParentsActionTypes = getDataListActionTypes(PUBLISHERS_PARENTS_MODULE);

export const publishersSettingsActionTypes = {
  GET_PUBLISHERS,
  GET_PUBLISHERS_SUCCESS,
  GET_PUBLISHERS_ERROR,
  GET_PUBLISHER_BLOCKED_BRANDS,
  GET_PUBLISHER_BLOCKED_BRANDS_SUCCESS,
  GET_PUBLISHER_BLOCKED_BRANDS_ERROR,
  BLOCK_PUBLISHER_BRAND,
  UNBLOCK_PUBLISHER_BRAND,
  CREATE_PUBLISHER,
  CREATE_PUBLISHER_SUCCESS,
  CREATE_PUBLISHER_ERROR,
  EDIT_PUBLISHER,
  EDIT_PUBLISHER_SUCCESS,
  EDIT_PUBLISHER_ERROR,
  DELETE_PUBLISHER,
  DELETE_PUBLISHER_SUCCESS,
  DELETE_PUBLISHER_ERROR,
  SET_SEARCH: publishersSettingsFiltersActionTypes.CHANGE_SEARCH,
  SET_GROUP,
  SET_GAINZ,
  RESET_FILTERS,
  ADD_PUBLISHERS_PARENTS,
  DELETE_PUBLISHERS_PARENTS,
};
